import React from "react";
import AdminAddProductPage from "components/templates/adminAddProductPage/AdminAddProductPage";
import AdminLayout from "components/layouts/AdminLayout";
import { Helmet } from "react-helmet";

const AdminAddProduct = () => {
  return (
    <>
      <Helmet>
        <title>Add Product - Admin Panel</title>
        <meta
          name="description"
          content="Add a new product to the platform in the administrative panel."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <AdminLayout>
        <AdminAddProductPage />
      </AdminLayout>
    </>
  );
};

export default AdminAddProduct;
