import React from "react";
import styles from "../forgetPasswordPage.module.css";
import { useHttpClient } from "hooks/useHttpClient";
import { Button, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const ResetPasswordScreen = (props) => {
  const navigate = useNavigate();

  const { error, sendRequest } = useHttpClient();

  const onFinish = async (values) => {
    try {
      // const loginResult = 
      await sendRequest(
        "/auth/password/reset-password",
        "POST",
        JSON.stringify({
          newPassword: values.password,
          confirmNewPassword: values.confirmPassword,
        })
      );
      if (!error) {
        props.setScreen(props.screen + 1)
      }
    } catch (err) {
      console.log(err)
    }
  };
  return (
    <div className={styles.loginDiv}>
      <h1>Set New Password</h1>
      <p className={styles.signupNavigation}>
        Enter a new password for your account
      </p>
      <Form
        name="basic"
        style={{ maxWidth: "100%" }}
        onFinish={onFinish}
        autoComplete="on"
      >
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please enter your new password!",
            },
          ]}
          className={styles.formItem}
        >
          <Input.Password placeholder="Password" className={styles.input} />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Please enter your new password!",
            },
          ]}
          className={styles.formItem}
        >
          <Input.Password placeholder="Confirm Password" className={styles.input} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className={styles.button}>
            Reset Password
          </Button>
        </Form.Item>
        <Button
          type="primary"
          className={styles.backButton}
          onClick={() => navigate("/sign-in")}
        >
          <ArrowLeftOutlined /> Back to Sign in
        </Button>
      </Form>
    </div>
  );
};

export default ResetPasswordScreen;
