import React from "react";
import styles from "../forgetPasswordPage.module.css";
import { Button, Form } from "antd";
import { useNavigate } from "react-router-dom";
import { CheckCircleOutlined } from "@ant-design/icons";

const SuccessScreen = () => {
  const navigate = useNavigate();


  const onFinish = async () => {
    navigate("/sign-in");
  };

  return (
    <div className={styles.loginDiv}>
      <h1>Password Changed</h1>
      <p className={styles.signupNavigation}>
        Your Password has been changed successfully.
      </p>
      <div className={styles.successDiv}>
        <CheckCircleOutlined className={styles.successIcon}/>
      </div>
      <Form
        name="basic"
        style={{ maxWidth: "100%" }}
        onFinish={onFinish}
        autoComplete="on"
      >
        <Form.Item>
          <Button className={styles.button}>Sign In</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SuccessScreen;
