import React from "react";
import AdminLoginPage from "components/templates/adminLoginPage/AdminLoginPage";
import { Helmet } from "react-helmet";

const AdminLogin = () => {
  return (
    <>
      <Helmet>
        <title>Admin Signin - Admin Panel</title>
        <meta
          name="description"
          content="Sign in to the administrative panel to manage the website."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <AdminLoginPage />
    </>
  );
};

export default AdminLogin;
