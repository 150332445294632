import React from "react";
import {
  SearchOutlined,
  FilterFilled,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Button,
  Input,
  Space,
  Table,
  DatePicker,
  notification,
  Modal,
} from "antd";
import { useRef, useState, useEffect } from "react";
import { useHttpClient } from "hooks/useHttpClient";
import { Link } from "react-router-dom";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const ProductTable = (props) => {
  const navigate = useNavigate();
  const { sendRequest, isLoading, error } = useHttpClient();
  const [tableData, setTableData] = useState({
    data: [],
    totalRecords: 0,
    currentPage: 1,
    pageSize: 10,
    filters: null,
    sort: {},
  });
  const searchInput = useRef(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setTableData((prevTableData) => ({
      ...prevTableData,
      filters: {},
      sort: {},
    }));
  }, [props.clearFilters]);

  const getData = async () => {
    let filterParams = [];
    for (const key in tableData.filters) {
      filterParams.push(JSON.stringify({ [key]: tableData.filters[key] }));
    }
    const data = await sendRequest(
      `/admin/products/get-products?filters=${filterParams}&sort=${JSON.stringify(
        tableData.sort
      )}&page=${tableData.currentPage}&size=${tableData.pageSize}`
    );
    setTableData((prevTableData) => ({
      ...prevTableData,
      data: data.products,
      totalRecords: data.totalProducts,
    }));
  };

  useEffect(() => {
    getData();
  }, [
    tableData.currentPage,
    tableData.pageSize,
    tableData.filters,
    tableData.sort,
  ]);

  const deleteProduct = async (id, name) => {
    Modal.confirm({
      title: "Confirm",
      content: `Are you sure you want to delete the ${name} Product?`,
      okText: "Yes",
      cancelText: "No",
      className: "confirm-modal",
      async onOk() {
        try {
          await sendRequest(`/admin/products/delete-product/${id}`, "DELETE");
          if (!error) {
            notification.success({
              message: "Success",
              description: "Product Deleted Successfully",
              placement: "top",
              className: "error-notification",
            });
            setRefresh(!refresh);
          }
        } catch (err) {
          console.log(err);
        }
      },
      onCancel() {},
    });
  };

  const handleSearch = async (close, selectedKeys, dataIndex) => {
    close();
    setTableData((prevTableData) => ({
      ...prevTableData,
      filters: {
        ...prevTableData.filters,
        [dataIndex]: selectedKeys[0],
      },
    }));
  };

  const handleReset = (close, dataIndex, setSelectedKeys) => {
    setSelectedKeys([]);
    close();
    setTableData((prevTableData) => {
      // Create a new object without the property specified by dataIndex
      const { [dataIndex]: tmp, ...restFilters } = prevTableData.filters;
      return {
        ...prevTableData,
        filters: restFilters,
      };
    });
  };

  const onPageChangeHandler = async (current, size) => {
    setTableData((prevTableData) => ({
      ...prevTableData,
      currentPage: current,
      pageSize: size,
    }));
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(close, selectedKeys, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(close, selectedKeys, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              color: "var(--white)",
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(close, dataIndex, setSelectedKeys);
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }}>
        <DatePicker
          style={{ width: 188, marginBottom: 8, display: "block" }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] ? moment(selectedKeys[0], "YYYY-MM-DD") : null}
          format="YYYY-MM-DD"
          onChange={(date, dateString) =>
            setSelectedKeys(dateString ? [dateString] : [])
          }
          onOk={() => handleSearch(close, selectedKeys, dataIndex)}
          allowClear={false}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(close, selectedKeys, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            handleReset(close, dataIndex, setSelectedKeys);
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const regex = new RegExp(
        moment(value, "YYYY-MM-DD").format("YYYY-MM-DD")
      );
      return regex.test(record[dataIndex]);
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => moment(text, "YYYY-MM-DD").format("DD/MM/YYYY"),
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      render: (_, { _id, name }) => (
        <Link to={`/admin/products/get-products/${_id}`}>{name}</Link>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Category",
      dataIndex: "categoryId",
      key: "categoryId",
      ...getColumnSearchProps("categoryName"),
      render: (_, { categoryId }) => <div>{categoryId.name}</div>,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      //   ...getColumnSearchProps("quantity"),
      sorter: true,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      //   ...getColumnSearchProps("quantity"),
      sorter: true,
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      ...getColumnDateProps("createdAt"),
      sorter: true,
      render: (_, { createdAt }) => (
        <div>
          {new Date(createdAt).getDate() +
            "/" +
            (new Date(createdAt).getMonth() + 1) +
            "/" +
            new Date(createdAt).getFullYear() +
            " " +
            new Date(createdAt).getHours() +
            ":" +
            new Date(createdAt).getMinutes() +
            ":" +
            new Date(createdAt).getSeconds()}
        </div>
      ),
    },
    {
      title: "Date Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      ...getColumnDateProps("updatedAt"),
      sorter: true,
      render: (_, { updatedAt }) => (
        <div>
          {new Date(updatedAt).getDate() +
            "/" +
            (new Date(updatedAt).getMonth() + 1) +
            "/" +
            new Date(updatedAt).getFullYear() +
            " " +
            new Date(updatedAt).getHours() +
            ":" +
            new Date(updatedAt).getMinutes() +
            ":" +
            new Date(updatedAt).getSeconds()}
        </div>
      ),
    },
    {
      title: "Edit",
      dataIndex: "_id",
      key: "_id",
      render: (_, { _id }) => (
        <Button
          type="text"
          onClick={() => {
            navigate(`/administrator-panel/products/edit-product/${_id.toString()}`);
          }}
        >
          <EditOutlined />
        </Button>
      ),
      align: "center",
    },
    {
      title: "Delete",
      dataIndex: "_id",
      key: "_id",
      render: (_, { _id, name }) => (
        <Button type="text" onClick={() => deleteProduct(_id, name)}>
          <DeleteOutlined />
        </Button>
      ),
      align: "center",
    },
  ];

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={tableData.data}
      pagination={{
        size: "default",
        position: ["bottomCenter"],
        total: tableData.totalRecords,
        pageSize: tableData.pageSize,
        showSizeChanger: true,
        responsive: true,
        onChange: onPageChangeHandler,
      }}
      components={{
        header: {
          cell: ({ children }) => (
            <th style={{ textAlign: "center" }}>{children}</th>
          ),
        },
      }}
      bordered
      scroll={{
        x: "max-content",
      }}
      loading={isLoading}
      rowKey="_id"
      onChange={(pagination, filters, sorter) => {
        setTableData((prevTableData) => ({
          ...prevTableData,
          sort: { [sorter.field]: sorter.order === "ascend" ? 1 : -1 },
        }));
      }}
      style={{ width: "100%" }}
    />
  );
};

export default ProductTable;
