import React, { useState } from "react";
import styles from "./forgetPasswordPage.module.css";
import EmailScreen from "./components/EmailScreen";
import VerificationCodeScreen from "./components/VerificationCodeScreen";
import ResetPasswordScreen from "./components/ResetPasswordScreen";
import SuccessScreen from "./components/SuccessScreen";

const ForgetPasswordPage = () => {
  const [screen, setScreen] = useState(1);
  const [email, setEmail] = useState("");
  return (
    <div className={styles.supportLogin}>
        {screen === 0 && (
          <EmailScreen
            screen={screen}
            setScreen={setScreen}
            setEmail={setEmail}
          />
        )}
        {screen === 1 && (
          <VerificationCodeScreen
            screen={screen}
            setScreen={setScreen}
            email={email}
          />
        )}
        {screen === 2 && (
          <ResetPasswordScreen screen={screen} setScreen={setScreen} />
        )}
        {screen === 3 && <SuccessScreen />}
    </div>
  );
};

export default ForgetPasswordPage;
