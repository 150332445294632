import React from "react";
import baseFrontendUrl from "constants/baseFrontendUrl"
import AdminDashboard from "components/templates/adminDashboard/AdminDashboard";

export const metadata = {
  title: "Shri Shyam Surgi Pharma",
  description: `Welcome to Shri Shyam Surgi Pharma - Your one-stop destination for high-quality nutritional food items and 
                surgical products in India. Discover a wide range of products from trusted brands. Shop with confidence and 
                enjoy fast, secure delivery. Explore our diverse catalog now!`,
  Keywords: [
    "Shri Shyam Surgi Pharma",
    "nutritional food items",
    "surgical products",
    "pharma wholesale store India",
    "high-quality products",
    "trusted brands",
    "online pharma store",
    "surgical supplies India",
    "nutritional supplements",
    "buy surgical instruments",
    "pharma wholesale supplier",
  ],
  canonicalUrl: "/",
  openGraph: {
    title: "Shri Shyam Surgi Pharma",
    description: `Welcome to Shri Shyam Surgi Pharma - Your one-stop destination for high-quality nutritional food items 
                  and surgical products in India. Discover a wide range of products from trusted brands. Shop with confidence 
                  and enjoy fast, secure delivery. Explore our diverse catalog now!`,
    url: baseFrontendUrl,
    siteName: "Shri Shyam Surgi Pharma",
    images: [
      {
        url: `${baseFrontendUrl}/og.png`,
        width: 800,
        height: 600,
      },
    ],
    locale: "en_US",
    type: "website",
  },
  twitter: {
    card: "summary_large_image",
    title: "Shri Shyam Surgi Pharma",
    description: `Welcome to Shri Shyam Surgi Pharma - Your one-stop destination for high-quality nutritional food items 
                  and surgical products in India. Discover a wide range of products from trusted brands. Shop with confidence 
                  and enjoy fast, secure delivery. Explore our diverse catalog now!`,
    images: [
      {
        url: `${baseFrontendUrl}/og.png`,
        width: 800,
        height: 600,
      },
    ],
  },
};
export default function Home() {
  return <AdminDashboard />;
}
