import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

const NProgressBar = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(false);
  const [prevLoc, setPrevLoc] = useState("");
  
  NProgress.configure({ showSpinner: false });

  useEffect(() => {
    setPrevLoc(location.pathname);
    setProgress(true);
    NProgress.start();
    if (location.pathname === prevLoc) {
      setPrevLoc("");
    }
  }, [location]);

  useEffect(() => {
    setProgress(false);
    NProgress.done();
  }, [prevLoc]);

  return null;
};

export default NProgressBar;
