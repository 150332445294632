import React from "react";
import styles from "./adminLoginComponent.module.css";
import { Button, Form, Input } from "antd";
import { Link } from "react-router-dom"
import { useHttpClient } from "hooks/useHttpClient";

const AdminLoginComponent = (props) => {
  const { error, sendRequest } = useHttpClient();

  const onFinish = async (values) => {
    try {
      const loginResult = await sendRequest(
        "/admin/auth/email/sign-in",
        "POST",
        JSON.stringify({
          username: values.email,
          password: values.password,
        })
      );
      if (!error) {
        props.setEmail(values.email);
        props.setRegistered(true);
      }
    } catch (err) {
      console.log(err)
    }
  };
  return (
    <div className={styles.supportLogin}>
      <div className={styles.loginDiv}>
        <h1>Admin Sign In</h1>
        <Form
          name="basic"
          style={{ maxWidth: "100%" }}
          onFinish={onFinish}
          autoComplete="on"
          layout="vertical"
        >
          <Form.Item
            name="email"
            label="Username or Email"
            required={false}
            rules={[
              {
                required: true,
                message: "Please input your Username or Email!",
              },
            ]}
            className={styles.formItem}
          >
            <Input placeholder="Email" className={styles.input} />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            required={false}
            rules={[{ required: true, message: "Please input your password!" }]}
            className={styles.formItem}
            style={{ marginBottom: "0" }}
          >
            <Input.Password placeholder="Password" className={styles.input} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className={styles.button}>
              SIGN IN
            </Button>
          </Form.Item>
        </Form>
        <p>
          By continuing, you agree to our{" "}
          <Link
            to="https://drunkenbytes.vercel.app/terms-of-service"
            className={styles.link}
          >
            Terms & Conditions
          </Link>
          ,{" "}
          <Link
            to="https://drunkenbytes.vercel.app/privacy-policy"
            className={styles.link}
          >
            Privacy Policy
          </Link>{" "}
          and{" "}
          <Link
            to="https://drunkenbytes.vercel.app/cookies-policy"
            className={styles.link}
          >
            Cookie Policy
          </Link>
        </p>
      </div>
    </div>
  );
};

export default AdminLoginComponent;
