import React, { useState, useEffect } from "react";
import styles from "./stylesheets/adminLayout.module.css";
import { useNavigate } from "react-router-dom";

import {
  AppstoreOutlined,
  TeamOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DashboardOutlined,
  FundOutlined,
  SettingOutlined,
  TagsOutlined,
  SnippetsOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Button } from "antd";
const { Sider } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const items = [
  getItem("Dashboard", "/administrator-panel", <DashboardOutlined />),
  getItem("Product Categories", "product-categories", <ProfileOutlined />, [
    getItem("Add Category", "add-product-category"),
    getItem("Display All Categories", "all-product-categories"),
  ]),
  getItem("Products", "products", <AppstoreOutlined />, [
    getItem("Add Product", "add-product"),
    getItem("Product Inventory", "products-inventory"),
  ]),
  getItem("Customers", "customers", <TeamOutlined />, [
    getItem("Display All Customers", "all-customers"),
  ]),
  getItem("Discounts", "discounts", <TagsOutlined />, [
    getItem("Display All Discounts", "all-discounts"),
    getItem("Add Discount", "add-discount"),
  ]),
  getItem("Orders", "orders", <SnippetsOutlined />, [
    getItem("Display All Orders", "all-orders"),
    getItem("Display Un Shipped Orders", "unshipped-orders"),
  ]),
  getItem("Transactions", "transactions", <FundOutlined />, [
    getItem("Display All Transactions", "all-transactions"),
  ]),
  // {
  //   type: 'divider',
  // },
  // getItem('Navigation Three', 'sub4', <SettingOutlined />, [
  //   getItem('Option 9', '9'),
  //   getItem('Option 10', '10'),
  //   getItem('Option 11', '11'),
  //   getItem('Option 12', '12'),
  // ]),
];

export default function AdminLayout({ children }) {
  const [collapsed, setCollapsed] = useState(true);

  const navigate = useNavigate();

  const handleMenuClick = ({ key, keyPath }) => {
    if (keyPath.length === 1) {
      navigate(key);
    } else if (keyPath.length === 2) {
      navigate(`/administrator-panel/${keyPath[1]}/${keyPath[0]}`);
    }
  };

  // Function to handle window resize
  const handleResize = () => {
    if (window.innerWidth > 992) {
      setCollapsed(false);
    } else if (window.innerWidth <= 992 && window.innerWidth >= 768) {
      setCollapsed(true);
    }
  };

  // Add a listener to handle window resize events
  useEffect(() => {
    handleResize(); // Set initial collapsed state
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={`${styles.adminLayout} admin-layout`}>
      <div className={styles.mobileTopBar}>
        <Button
          onClick={() => setCollapsed(!collapsed)}
          className={styles.collapseButton}
        >
          <strong>Admin Dashboard</strong>{" "}
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
      </div>
      <div>
        <Sider
          className={`${styles.adminLayoutSider} ${
            collapsed ? styles.siderHidden : styles.sider
          }`}
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          breakpoint="lg" // Add this prop to specify the breakpoint at which the menu will collapse
          trigger={null} // Set trigger prop to null to hide the default trigger button
        >
          <Menu
            defaultSelectedKeys={["/administrator-panel"]}
            mode="inline"
            items={items}
            inlineCollapsed={collapsed}
            className={styles.sidebarMenu}
            onClick={handleMenuClick} // Add the onClick handler for menu item clicks
          />
          <Button
            onClick={() => setCollapsed(!collapsed)}
            className={`${styles.collapseButton} ${styles.collapseButtonHidden}`}
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
        </Sider>
      </div>
      <div className={styles.childContainer}>{children}</div>
    </div>
  );
}
