import React, { useState } from "react";
import styles from "./adminProductsInventoryPage.module.css";
import AdminPanelHeader from "components/modules/AdminPanelHeader";
import ProductTable from "./components/ProductTable";
import CustomButton from "components/elements/CustomButton";

const AdminProductsInventoryPage = () => {
  const [clearFilters, setClearFilter] = useState(false);
  return (
    <div className={styles.adminDashboard}>
      <AdminPanelHeader
        title={"View Products and Inventory"}
        content={
          "Welcome to the View Products and Inventory page. Here, you can easily access a comprehensive overview of all our products along with their current inventory status. Whether you want to browse through our diverse product offerings or keep a close eye on stock levels, this page provides all the essential tools for efficient inventory management. With user-friendly search and filtering options, you can swiftly find specific products or sort them based on your preferences. Stay informed and make well-informed decisions as you explore the product details and inventory data. We are committed to providing you with a seamless experience, empowering you to streamline your product management effortlessly. "
        }
      />
      <div className={styles.loginDiv}>
        <div className={styles.clearFilterButton}>
          <CustomButton
            type="Gradient"
            text="Clear All Filter"
            onClickHandler={() => {
              setClearFilter(!clearFilters);
            }}
          />
        </div>
        <ProductTable clearFilters={clearFilters}/>
      </div>
    </div>
  );
};

export default AdminProductsInventoryPage;
