import React from "react";
import styles from "./codeVerificationPage.module.css";
import { useHttpClient } from "hooks/useHttpClient";
import { Button, Form, Input, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const CodeVerificationPage = (props) => {
  const navigate = useNavigate
  const { error, sendRequest } = useHttpClient();

  const onFinish = async (values) => {
    try {
      const result = await sendRequest(
        "/admin/auth/email/verify-verification-code",
        "POST",
        JSON.stringify({
          verificationCode: values.verificationCode,
        })
      );
      if (!error) {
        notification.success({
          message: "Success",
          description: result.message,
          placement: "top",
          className: "error-notification",
        });
        navigate("/administrator-panel")
      }
    } catch (err) {
      console.log(err);
    }
  };

  const resendEmail = async (values) => {
    try {
      const result = await sendRequest(
        "/admin/auth/email/resend-verification-code",
        "POST"
      );
      console.log(result);
      if (!error) {
        notification.success({
          message: "Success",
          description: result.message,
          placement: "top",
          className: "error-notification",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles.supportLogin}>
    <div className={styles.loginDiv}>
      <h1>Enter Verification Code</h1>
      <p className={styles.signupNavigation}>
        Welcome {" "}
        <strong style={{ color: "var(--black)" }}>{props.email}</strong>.
        We have sent a code to your email address.
      </p>
      <Form
        name="basic"
        style={{ maxWidth: "100%" }}
        onFinish={onFinish}
        autoComplete="on"
      >
        <Form.Item
          name="verificationCode"
          rules={[
            {
              required: true,
              message: "Please enter verification code!",
            },
          ]}
          className={styles.formItem}
        >
          <Input placeholder="Verification Code" className={styles.input} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className={styles.button}>
            Sign In
          </Button>
        </Form.Item>
        <p className={styles.emailNotReceived}>
          Didn't receive the email?{" "}
          <button type="button" onClick={resendEmail}>
            Click here to resend
          </button>
        </p>
        <Button
          type="primary"
          className={styles.backButton}
          onClick={() => navigate("/administrator-panel/sign-in")}
        >
          <ArrowLeftOutlined /> Back to Sign in
        </Button>
      </Form>
    </div>
    </div>

  );
};

export default CodeVerificationPage;
