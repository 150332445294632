import React, { useContext, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import CookieBar from "components/modules/CookieBar";
import CookiePreferencesButton from "components/modules/CookiePreferencesButton";
import Footer from "components/modules/Footer";
import NProgressBar from "components/modules/NProgressBar";
import Navbar from "components/modules/Navbar";
import ScrollToTop from "components/modules/ScrollToTop";

import Home from "app/page";
import Login from "app/sign-in/page";
import SignUp from "app/sign-up/page";
import ForgetPassword from "app/forget-password/page";
import AdminAddProductCategory from "app/administrator-panel/product-categories/add-product-category/page";
import AdminAllProductCategories from "app/administrator-panel/product-categories/all-product-categories/page";
import AdminAddProduct from "app/administrator-panel/products/add-product/page";
import AdminEditProduct from "app/administrator-panel/products/edit-product/[id]/page";
import AdminLogin from "app/administrator-panel/sign-in/page";
import AdminProductsInventory from "app/administrator-panel/products/products-inventory/page";
import AdminDashboard from "app/administrator-panel/page";
import AdminEditProductCategory from "app/administrator-panel/product-categories/edit-product-category/[id]/page";

const Main = () => {
  //   const { dispatch, loggedInDetails } = useContext(AppContext);
  //   useEffect(() => {
  //     const setData = async () => {
  //       const loginData = JSON.parse(localStorage.getItem("NW_UserDetails"));
  //       if (loginData) {
  //         dispatch({
  //           type: "UserLogin",
  //           payload: loginData,
  //         });
  //       }
  //     };
  //     setData();
  //   }, []);

  return (
    <>
      <NProgressBar />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sign-in" element={<Login />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/administrator-panel" element={<AdminDashboard />} />
        <Route
          path="/administrator-panel/product-categories/add-product-category"
          element={<AdminAddProductCategory />}
        />
        <Route
          path="/administrator-panel/product-categories/all-product-categories"
          element={<AdminAllProductCategories />}
        />
        <Route
          path="/administrator-panel/product-categories/edit-product-category/:productCategoryId"
          element={<AdminEditProductCategory />}
        />
        <Route
          path="/administrator-panel/products/add-product"
          element={<AdminAddProduct />}
        />
        <Route
          path="/administrator-panel/products/edit-product/:productId"
          element={<AdminEditProduct />}
        />
        <Route
          path="/administrator-panel/products/products-inventory"
          element={<AdminProductsInventory />}
        />
        <Route path="/administrator-panel/sign-in" element={<AdminLogin />} />
      </Routes>
      <CookiePreferencesButton />
      <ScrollToTop />
      <CookieBar />
      <Footer />
    </>
  );
};

export default Main;
