import React, { useState } from "react";
import styles from "./adminAllProductCategoriesPage.module.css";
import AdminPanelHeader from "components/modules/AdminPanelHeader";
import ProductCategoryTable from "./components/ProductCategoryTable";
import CustomButton from "components/elements/CustomButton";

const AdminAllProductCategoriesPage = () => {
  const [clearFilters, setClearFilter] = useState(false);
  return (
    <div className={styles.adminDashboard}>
      <AdminPanelHeader
        title={"View All Product Categories"}
        content={
          "Welcome to the View All Product Categories page. Here, you can explore and manage the extensive list of product categories available on our platform. Whether you're looking to make updates, add new subcategories, or simply gain insights into our diverse range of products, this page provides a comprehensive overview of all categories. Use the filtering and search options to quickly find specific categories or browse through the entire list to discover the breadth of products we offer. Thank you for being part of our platform, where we strive to make the shopping experience seamless and enjoyable for every user."
        }
      />
      <div className={styles.loginDiv}>
        <div className={styles.clearFilterButton}>
          <CustomButton
            type="Gradient"
            text="Clear All Filter"
            onClickHandler={() => {
              setClearFilter(!clearFilters);
            }}
          />
        </div>
        <ProductCategoryTable clearFilters={clearFilters}/>
      </div>
    </div>
  );
};

export default AdminAllProductCategoriesPage;
