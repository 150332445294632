import styles from "./adminAddProductPage.module.css";
import AdminPanelHeader from "components/modules/AdminPanelHeader";
import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Spin,
  Upload,
  Modal,
  Select,
  notification,
} from "antd";
import { useHttpClient } from "hooks/useHttpClient";
import { PlusOutlined } from "@ant-design/icons";
import { uploadImage, deleteImage } from "utils/uploadImage";
import { useNavigate } from "react-router-dom";

const AdminAddProductPage = (props) => {
  const navigate = useNavigate();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const { error, sendRequest, isLoading } = useHttpClient();
  const [form] = Form.useForm();
  const [selectedProductCategory, setSelectedProductCategory] = useState(null);
  const [productCategories, setProductCategories] = useState([]);

  const getProductCategories = async (props) => {
    try {
      const result = await sendRequest(
        "/admin/product-categories/all-product-categories"
      );
      setProductCategories(
        result.map((item) => ({ value: item._id, label: item.name }))
      );
    } catch (err) {
      console.log(error);
    }
  };
  const fetchEditData = async () => {
    const result = await sendRequest(
      `/admin/products/get-product/${props.productId}`
    );
    form.setFieldsValue({
      productName: result.product.name,
      productDescription: result.product.description,
      productPrice: result.product.price,
      productQuantity: result.product.quantity,
    });
    setSelectedProductCategory(result.product.categoryId);
  };
  useEffect(() => {
    getProductCategories();
    if (props.mode === "edit") fetchEditData();
  }, []);

  const uploadMainImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    try {
      const res = await uploadImage(file, "products");
      onSuccess("Ok");
      // Find the file in the fileList by its uid
      const existingFileIndex = fileList.findIndex((f) => f.uid === file.uid);
      // If the file is already in the fileList, update its URL
      if (existingFileIndex !== -1) {
        setFileList((prevFileList) => {
          const updatedFileList = [...prevFileList];
          updatedFileList[existingFileIndex] = {
            uid: prevFileList[existingFileIndex].uid,
            uid: prevFileList[existingFileIndex].name,
            url: res.file.url,
            status: "done",
          };
          return updatedFileList;
        });
      } else {
        // If the file is not in the fileList, add it with the URL
        setFileList((prevFileList) => [
          ...prevFileList,
          {
            uid: file.uid,
            name: file.name,
            status: "done",
            url: res.file.url,
          },
        ]);
      }
    } catch (err) {
      console.log(err);
      onError({ err });
    }
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    setPreviewImage(file.url);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList((prevFileList) => {
      const uniqueFiles = newFileList.filter(
        (newFile) => !prevFileList.some((file) => file.uid === newFile.uid)
      );
      return [...prevFileList, ...uniqueFiles];
    });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const removeMainImage = async (fileToRemove) => {
    setFileList((prevFileList) =>
      prevFileList.filter((file) => file.uid !== fileToRemove.uid)
    );
    const urlObject = new URL(fileToRemove.url);
    const fileName = urlObject.pathname.substring(
      urlObject.pathname.lastIndexOf("/") + 1
    );
    deleteImage(fileName, "products");
  };

  const onFinish = async (values) => {
    if (fileList.length == 0) {
      notification.error({
        message: "No Image Uploaded",
        description: "Please upload at least one product image to proceed.",
        placement: "top",
        className: "error-notification",
      });
      return;
    }
    try {
      const url =
        props.mode === "edit"
          ? `/admin/products/edit-product/${props.productCategoryId}`
          : "/admin/products/add-product";
      const method = props.mode === "edit" ? "PUT" : "POST";
      const result = await sendRequest(
        url,
        method,
        JSON.stringify({
          name: values.productName,
          description: values.productDescription,
          categoryId: selectedProductCategory,
          quantity: values.productQuantity,
          price: values.productPrice,
          images: fileList.map((file) => file.url),
          discountId: undefined,
        })
      );
      if (!error) {
        notification.success({
          message: "Success",
          description: result.message,
          placement: "top",
          className: "error-notification",
        });
        form.resetFields();
        setFileList([]);
        setSelectedProductCategory(null);
        if (props.mode === "edit") {
          navigate("/administrator-panel/products/products-inventory");
        }
      }
    } catch (err) {}
  };

  return (
    <div className={styles.adminDashboard}>
      <AdminPanelHeader
        title={props.mode === "edit" ? "Edit Product" : "Add New Product"}
        content={
          props.mode === "edit"
            ? "Welcome to the Edit Product page. Here, you can modify the details of an existing product listed on our platform. Please make the necessary changes and update the required information. You can also upload new images or replace the existing ones to maintain an appealing product listing."
            : "Welcome to the Add New Product page. Here, you can add details for a new product to be listed on our platform. Please fill in the required information and upload images to create a compelling product listing."
        }
      />
      <div className={styles.loginDiv}>
        <Spin size="large" spinning={isLoading}>
          <Form
            scrollToFirstError
            layout="vertical"
            name="basic"
            form={form}
            style={{ maxWidth: "100%" }}
            onFinish={onFinish}
            autoComplete="on"
            className="create-nft-form"
            initialValues={{
              productQuantity: 100,
            }}
          >
            <Form.Item
              label="Product Name"
              required
              name="productName"
              rules={[
                {
                  required: true,
                  message: "Please enter Product Name!",
                },
              ]}
              className={styles.formItem}
            >
              <Input
                placeholder="Enter Product Name"
                className={styles.input}
              />
            </Form.Item>
            <Form.Item
              label="Product Category"
              required
              name="productCategory"
              className={styles.formItem}
            >
              <Select
                className={styles.input}
                onChange={(value) => setSelectedProductCategory(value)}
                options={productCategories}
                aria-label="select-product-category"
                placeholder="Select Product Category"
              />
            </Form.Item>
            <Form.Item
              label="Product Price"
              required
              name="productPrice"
              rules={[
                {
                  required: true,
                  message: "Please enter Product Price!",
                },
              ]}
              className={styles.formItem}
            >
              <Input
                placeholder="Enter Product Price"
                className={styles.input}
              />
            </Form.Item>
            <Form.Item
              label="Product Images"
              rules={[
                {
                  required: true,
                  message: "Please upload Product Photo",
                },
              ]}
              className={styles.formItem}
            >
              <>
                <Upload
                  accept="image/*"
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  customRequest={uploadMainImage}
                  onRemove={removeMainImage}
                >
                  {fileList.length >= 3 ? null : uploadButton}
                </Upload>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{
                      width: "100%",
                    }}
                    src={previewImage}
                  />
                </Modal>
              </>
            </Form.Item>
            <Form.Item
              label="Product Description"
              required
              name="productDescription"
              rules={[
                {
                  required: true,
                  message: "Please enter Product Description!",
                },
              ]}
              className={styles.formItem}
            >
              <Input.TextArea
                rows={4}
                placeholder="Enter Product Description"
                className={styles.inputTextArea}
              />
            </Form.Item>
            <Form.Item
              label="Available Product Quantity"
              required
              name="productQuantity"
              rules={[
                {
                  required: true,
                  message: "Please enter Product Quantity!",
                },
              ]}
              className={styles.formItem}
            >
              <Input
                placeholder="Enter Available Product Quantity"
                className={styles.input}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.button}
              >
                {props.mode === "edit" ? "EDIT PRODUCT" : "CREATE PRODUCT"}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default AdminAddProductPage;
