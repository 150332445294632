import React, { useContext } from "react";
import styles from "./loginPage.module.css";
import { Button, Form, Input } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useHttpClient } from "hooks/useHttpClient";
import AppContext from "context/AppContext";

const LoginPage = () => {
  const navigate = useNavigate();
  const { error, sendRequest } = useHttpClient();
  const { dispatch } = useContext(AppContext);

  const onFinish = async (values) => {
    try {
      // const loginResult = 
      await sendRequest(
        "/auth/sign-in",
        "POST",
        JSON.stringify({
          email: values.email,
          password: values.password,
        })
      );
      if (!error) {
        dispatch({
          type: "UserLogin",
        });
        navigate("/");
      }
    } catch (err) {}
  };
  return (
    <div className={styles.supportLogin}>
      <div className={styles.loginDiv}>
        <h1>Sign In</h1>
        <p className={styles.signupNavigation}>
          Doesn't have an account yet?{" "}
          <Link to="/sign-up" className={styles.signUpLink}>
            Sign Up
          </Link>
        </p>
        <Form
          name="basic"
          style={{ maxWidth: "100%" }}
          onFinish={onFinish}
          autoComplete="on"
          layout="vertical"
        >
          <Form.Item
            name="email"
            label="Email"
            required={false}
            rules={[
              {
                type: "email",
                message: "The input is not valid Email!",
              },
              {
                required: true,
                message: "Please input your Email!",
              },
            ]}
            className={styles.formItem}
          >
            <Input placeholder="Email" className={styles.input} />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            required={false}
            rules={[{ required: true, message: "Please input your password!" }]}
            className={styles.formItem}
            style={{ marginBottom: "0" }}
          >
            <Input.Password placeholder="Password" className={styles.input} />
          </Form.Item>
          <div className={styles.forgetPassword}>
            <Link to="/forget-password" className={styles.forgetPasswordLink}>
              Forget Password?
            </Link>
          </div>
          <Form.Item>
            <Button type="primary" htmlType="submit" className={styles.button}>
              SIGN IN
            </Button>
          </Form.Item>
        </Form>
        <p>
          By continuing, you agree to our{" "}
          <Link
            to="https://drunkenbytes.vercel.app/terms-of-service"
            className={styles.link}
          >
            Terms & Conditions
          </Link>
          ,{" "}
          <Link
            to="https://drunkenbytes.vercel.app/privacy-policy"
            className={styles.link}
          >
            Privacy Policy
          </Link>{" "}
          and{" "}
          <Link
            to="https://drunkenbytes.vercel.app/cookies-policy"
            className={styles.link}
          >
            Cookie Policy
          </Link>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
