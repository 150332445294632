import React, { useState } from "react";
import RegisterPage from "./components/RegisterPage";
import VerificationEmailPage from "./components/VerificationEmailPage";

const SignUpPage = () => {
  const [email, setEmail] = useState(null);
  const [registered, setRegistered] = useState(false);
  return registered ? (
    <VerificationEmailPage email={email} />
  ) : (
    <RegisterPage setEmail={setEmail} setRegistered={setRegistered} />
  );
};

export default SignUpPage;
