import styles from "./stylesheets/navbar.module.css";
import React from "react";
// import { useContext } from "react";
// import AppContext from "context/AppContext";
import { Link } from "react-router-dom";
import {
  UserOutlined,
  FileTextOutlined,
  BulbOutlined,
  LogoutOutlined,
  WalletOutlined,
  KeyOutlined,
  ExclamationCircleOutlined,
  LineChartOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { Dropdown, Avatar, notification } from "antd";
import SideDrawer from "./SideDrawer";
// import { useHttpClient } from "hooks/useHttpClient";
// import Cookies from "js-cookie";

const Navbar = () => {
  // const { loggedInDetails } = useContext(AppContext);
  // const { dispatch } = useContext(AppContext);
  // const { error, sendRequest, clearError } = useHttpClient();
  const loggedInDetails = {
    isLoggedIn: true,
  };
  // const sendLogoutRequest = async () => {
  //   try {
  //     Cookies.remove("db_login");
  //     Cookies.remove("db_login_address");
  //     dispatch({
  //       type: "UserLogout",
  //     });
  //     await sendRequest("/user/logout", "POST");
  //   } catch (err) {}
  // };

  // const sendLoginRequest = async () => {
  //   await sendRequest(
  //     "/user/login",
  //     "POST",
  //     JSON.stringify({
  //       accountAddress: address,
  //       signedData: data,
  //       message: variables.message,
  //     })
  //   );
  //   if (error) {
  //     Cookies.remove("db_login");
  //     Cookies.remove("db_login_address");
  //     dispatch({
  //       type: "UserLogout",
  //     });
  //   }
  //   Cookies.set("db_login", "true");
  //   Cookies.set("db_login_address", address);
  //   dispatch({
  //     type: "UserLogin",
  //     payload: { address: address },
  //   });
  //   if (pathname === "/hold") router.back();
  // };

  const items = [
    {
      key: "1",
      label: (
        <Link to="/profile">
          <div className={styles.avatarItem}>
            <UserOutlined className={styles.avatarItemIcon} />
            Profile
          </div>
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link to="/wallet">
          <div className={styles.avatarItem}>
            <WalletOutlined className={styles.avatarItemIcon} />
            Wallet
          </div>
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link to="/api-keys">
          <div className={styles.avatarItem}>
            <KeyOutlined className={styles.avatarItemIcon} />
            Manage API Keys
          </div>
        </Link>
      ),
    },
    {
      key: "4",
      label: (
        <Link to="/transactions">
          <div className={styles.avatarItem}>
            <LineChartOutlined className={styles.avatarItemIcon} />
            View Transactions
          </div>
        </Link>
      ),
    },
    {
      key: "5",
      label: (
        <Link to="/issues">
          <div className={styles.avatarItem}>
            <ExclamationCircleOutlined className={styles.avatarItemIcon} />
            View Issues
          </div>
        </Link>
      ),
    },
    {
      key: "6",
      label: (
        <Link to="/template">
          <div className={styles.avatarItem}>
            <FileTextOutlined className={styles.avatarItemIcon} />
            Manage Templates
          </div>
        </Link>
      ),
    },
    {
      key: "7",
      label: (
        <Link to="/tickets">
          <div className={styles.avatarItem}>
            <BulbOutlined className={styles.avatarItemIcon} />
            View Tickets
          </div>
        </Link>
      ),
    },
    {
      key: "8",
      label: (
        <button
          className={styles.avatarItemButton}
          // onClick={() => disconnect()}
        >
          <div className={styles.avatarItem}>
            <LogoutOutlined className={styles.avatarItemIcon} />
            Logout
          </div>
        </button>
      ),
    },
  ];
  return (
    <header className={styles.navbar}>
      <div className={styles.logoContainer}>
        <Link to="/">
          <img
            alt="Shri Shyam Surgi Pharma Logo"
            src="/SSIPLogo.png"
            className={styles.logo}
          />
        </Link>
      </div>
      <div className={styles.buttonsContainer}>
        {/* <div className={styles.linksContainer}>
          <Link to="/pricing" className={styles.link}>
            Pricing
          </Link>
          <Link to="/documentation" className={styles.link}>
            Documentation
          </Link>
        </div> */}
        {loggedInDetails.isConnected ? (
          <Dropdown
            menu={{
              items,
            }}
            placement="bottom"
          >
            <Avatar
              type="button"
              size="large"
              icon={<UserOutlined />}
              className={styles.avatar}
            />
          </Dropdown>
        ) : (
          <Link to="/sign-in" className={styles.button}>
            <span className={`${styles.link} ${styles.hideLast}`}>Sign in</span>
          </Link>
        )}
        <Link to="/sign-in" className={styles.button}>
          <Avatar
            type="button"
            // size="large"
            icon={<ShoppingCartOutlined />}
            className={styles.avatar}
          />
          <span className={`${styles.link} ${styles.hide}`}>Cart</span>
        </Link>
        <SideDrawer />
      </div>
    </header>
  );
};

export default Navbar;
