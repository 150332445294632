import React from "react";
import styles from "./stylesheets/adminPanelHeader.module.css";
import { Breadcrumb } from "antd";
import { useLocation } from "react-router-dom";

const AdminPanelHeader = (props) => {
  const location = useLocation();
  const pathParts = location.pathname.split("/").filter((part) => part);
  let pathSoFar = "";

  const breadcrumbItems = pathParts.map((part, index) => {
    pathSoFar += "/" + part;
    const title = part
      .replace(/-/g, " ")
      .replace(/\b\w/g, (c) => c.toUpperCase()); // Capitalize every word
    const path = index === pathParts.length - 1 ? undefined : pathSoFar;
    return { title, href: path };
  });

  return (
    <div className={styles.adminPanelHeader}>
      <Breadcrumb items={breadcrumbItems} />
      <h1>{props.title}</h1>
      <p>{props.content}</p>
    </div>
  );
};

export default AdminPanelHeader;
