import React from "react";
import styles from "../forgetPasswordPage.module.css";
import { useHttpClient } from "hooks/useHttpClient";
import { Button, Form, Input, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const VerificationCodeScreen = (props) => {
  const navigate = useNavigate();
  const { error, sendRequest } = useHttpClient();

  const onFinish = async (values) => {
    try {
      // const result = 
      await sendRequest(
        "/auth/password/verify-reset-code",
        "POST",
        JSON.stringify({
          resetCode: values.verificationCode,
        })
      );
      if (!error) {
        props.setScreen(props.screen + 1);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const resendEmail = async (values) => {
    try {
      const result = await sendRequest(
        "/auth/password/resend-verification-code",
        "POST"
      );
      console.log(result);
      if (!error) {
        notification.success({
          message: "Success",
          description: result.message,
          placement: "top",
          className: "error-notification",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles.loginDiv}>
      <h1>Password Reset</h1>
      <p className={styles.signupNavigation}>
        We have sent a code to{" "}
        <strong style={{ color: "var(--black)" }}>{props.email}</strong>
      </p>
      <Form
        name="basic"
        style={{ maxWidth: "100%" }}
        onFinish={onFinish}
        autoComplete="on"
      >
        <Form.Item
          name="verificationCode"
          rules={[
            {
              required: true,
              message: "Please enter verification code!",
            },
          ]}
          className={styles.formItem}
        >
          <Input placeholder="Verification Code" className={styles.input} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className={styles.button}>
            Continue
          </Button>
        </Form.Item>
        <p className={styles.emailNotReceived}>
          Didn't receive the email?{" "}
          <button type="button" onClick={resendEmail}>
            Click here to resend
          </button>
        </p>
        <Button
          type="primary"
          className={styles.backButton}
          onClick={() => navigate("/sign-in")}
        >
          <ArrowLeftOutlined /> Back to Sign in
        </Button>
      </Form>
    </div>
  );
};

export default VerificationCodeScreen;
