import React, { useState } from "react";
import styles from "./registerPage.module.css";
import { Button, Form, Input, Select, notification } from "antd";
import { Link } from "react-router-dom";
import { useHttpClient } from "hooks/useHttpClient";
import countryCodes from "constants/countryCodes";

const RegisterPage = (props) => {
  const { error, sendRequest } = useHttpClient();
  const [countryCode, setCountryCode] = useState("+91");

  const onFinish = async (values) => {
    try {
      const signupResult = await sendRequest(
        "/auth/sign-up",
        "POST",
        JSON.stringify({
          name: values.name,
          email: values.email,
          password: values.password,
          confirmPassword: values.confirmPassword,
          countryCode,
          mobile: values.mobile,
        })
      );
      if (error !== null) {
        notification.success({
          message: "Success",
          description: signupResult.message,
          placement: "top",
          className: "error-notification",
        });
        props.setRegistered(true);
        props.setEmail(values.email);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles.supportLogin}>
      <div className={styles.loginDiv}>
        <h1>Sign Up</h1>
        <p className={styles.signupNavigation}>
          Already have an account?{" "}
          <Link to="/sign-in" className={styles.signUpLink}>
            Sign In
          </Link>
        </p>
        <Form
          name="basic"
          style={{ maxWidth: "100%" }}
          onFinish={onFinish}
          autoComplete="on"
        >
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Please input your name!" }]}
            className={styles.formItem}
          >
            <Input placeholder="Name" className={styles.input} />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not valid Email!",
              },
              {
                required: true,
                message: "Please input your Email!",
              },
            ]}
            className={styles.formItem}
          >
            <Input placeholder="Email" className={styles.input} />
          </Form.Item>
          <Form.Item
            name="mobile"
            rules={[
              { required: true, message: "Please input your mobile number!" },
            ]}
            className={styles.formItem}
          >
            <div>
              <Select
                defaultValue="+91"
                options={countryCodes}
                className={`${styles.input} ${styles.countryCode}`}
                onChange={(value) => setCountryCode(value)}
              />
              <Input
                placeholder="Mobile Number"
                className={`${styles.input} ${styles.mobileField}`}
              />
            </div>
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
            className={styles.formItem}
          >
            <Input.Password placeholder="Password" className={styles.input} />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            rules={[
              { required: true, message: "Please confirm your password!" },
            ]}
            className={styles.formItem}
          >
            <Input.Password
              placeholder="Confirm Password"
              className={styles.input}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className={styles.button}>
              SIGN UP
            </Button>
          </Form.Item>
        </Form>
        <p>
          By creating an account or logging in, you agree to our{" "}
          <Link to="/terms-of-service" className={styles.link}>
            Terms & Conditions
          </Link>
          ,{" "}
          <Link to="/privacy-policy" className={styles.link}>
            Privacy Policy
          </Link>{" "}
          and{" "}
          <Link to="/cookies-policy" className={styles.link}>
            Cookie Policy
          </Link>
        </p>
      </div>
    </div>
  );
};

export default RegisterPage;
