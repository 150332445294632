import React from "react";
import AdminAddProductPage from "components/templates/adminAddProductPage/AdminAddProductPage";
import AdminLayout from "components/layouts/AdminLayout";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const AdminEditProduct = (props) => {
  const { productId } = useParams();
  return (
    <>
      <Helmet>
        <title>Edit Product - Admin Panel</title>
        <meta
          name="description"
          content="Edit an existing product in the platform from the administrative panel."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <AdminLayout>
        <AdminAddProductPage mode="edit" productId={productId} />
      </AdminLayout>
    </>
  );
};

export default AdminEditProduct;
