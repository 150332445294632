import React from "react";
import AdminProductsInventoryPage from "components/templates/adminProductsInventoryPage/AdminProductsInventoryPage";
import AdminLayout from "components/layouts/AdminLayout";
import { Helmet } from "react-helmet";

const AdminProductsInventory = () => {
  return (
    <>
      <Helmet>
        <title>Product Inventory - Admin Panel</title>
        <meta
          name="description"
          content="View and manage the inventory of products from the administrative panel."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <AdminLayout>
        <AdminProductsInventoryPage />
      </AdminLayout>
    </>
  );
};

export default AdminProductsInventory;
