import React from "react";
import AdminAddProductCategoryPage from "components/templates/adminAddProductCategoryPage/AdminAddProductCategoryPage";
import AdminLayout from "components/layouts/AdminLayout";
import { Helmet } from "react-helmet";

const AdminAddProductCategory = () => {
  return (
    <>
      <Helmet>
        <title>Add Product Category - Admin Panel</title>
        <meta
          name="description"
          content="Add new product categories in the administrative panel."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <AdminLayout>
        <AdminAddProductCategoryPage />
      </AdminLayout>
    </>
  );
};

export default AdminAddProductCategory;
