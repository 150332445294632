"use client"

import React from "react";
import styles from "./adminDashboard.module.css";
import AdminPanelHeader from "components/modules/AdminPanelHeader";

const AdminDashboardPage = () => {
  return (
    <div className={styles.adminDashboard}>
      <AdminPanelHeader
        title={"Form"}
        content={
          "ompiled client and server successfully in 1886 ms (4905 modules)"
        }
      />
      AdminDashboard
    </div>
  );
};

export default AdminDashboardPage;
