import React from "react";
import AdminLayout from "components/layouts/AdminLayout";
import AdminAllProductCategoriesPage from "components/templates/adminAllProductCategoriesPage/AdminAllProductCategoriesPage";
import { Helmet } from "react-helmet";

const AdminAllProductCategories = () => {
  return (
    <>
      <Helmet>
        <title>View All Product Categories - Admin Panel</title>
        <meta
          name="description"
          content="View all product categories in the administrative panel."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <AdminLayout>
        <AdminAllProductCategoriesPage />
      </AdminLayout>
    </>
  );
};

export default AdminAllProductCategories;
