import styles from "./adminAddProductCategoryPage.module.css";
import AdminPanelHeader from "components/modules/AdminPanelHeader";
import React, { useEffect } from "react";
import { Button, Form, Input, Spin, notification } from "antd";
import { useHttpClient } from "hooks/useHttpClient";
import { useNavigate } from "react-router-dom";

const AdminAddProductCategoryPage = (props) => {
  const { error, sendRequest, isLoading } = useHttpClient();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const fetchEditData = async () => {
    const result = await sendRequest(
      `/admin/product-categories/get-product-category/${props.productCategoryId}`
    );
    form.setFieldsValue({
      productCategoryName: result.productCategory.name,
      productCategoryDescription: result.productCategory.description,
    });
  };
  useEffect(() => {
    if (props.mode === "edit") fetchEditData();
  }, [props.mode]);

  const onFinish = async (values) => {
    try {
      const url =
        props.mode === "edit"
          ? `/admin/product-categories/edit-product-category/${props.productCategoryId}`
          : "/admin/product-categories/add-product-category";
      const method = props.mode === "edit" ? "PUT" : "POST";
      const result = await sendRequest(
        url,
        method,
        JSON.stringify({
          name: values.productCategoryName,
          description: values.productCategoryDescription,
        })
      );
      if (!error) {
        notification.success({
          message: "Success",
          description: result.message,
          placement: "top",
          className: "error-notification",
        });
        form.resetFields();
        if (props.mode === "edit") {
          navigate(
            "/administrator-panel/product-categories/all-product-categories"
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles.adminDashboard}>
      <AdminPanelHeader
        title={
          props.mode === "edit"
            ? "Edit Product Category"
            : "Add New Product Category"
        }
        content={
          props.mode === "edit"
            ? "Welcome to the Edit Product Category page. Here, you can make changes to the details of an existing product category on our platform. Please update the necessary information to accurately represent the category. Your modifications will help improve the organization and discoverability of products within this category. Thank you for ensuring a seamless shopping experience for our users."
            : "Welcome to the Add New Product Category page. Here, you can create a new product category to expand our platform's offerings. Please provide all the required information to set up the category effectively. By adding detailed and relevant information, you can enhance the product browsing experience for our users and enable better organization of products. Thank you for contributing to our diverse range of product categories and making our platform more comprehensive for shoppers."
        }
      />
      <div className={styles.loginDiv}>
        <Spin size="large" spinning={isLoading}>
          <Form
            scrollToFirstError
            layout="vertical"
            name="basic"
            form={form}
            style={{ maxWidth: "100%" }}
            onFinish={onFinish}
            autoComplete="on"
            className="create-nft-form"
          >
            <Form.Item
              label="Product Category Name"
              required
              name="productCategoryName"
              rules={[
                {
                  required: true,
                  message: "Please enter Product Category Name!",
                },
              ]}
              className={styles.formItem}
            >
              <Input
                placeholder="Enter Product Category Name"
                className={styles.input}
              />
            </Form.Item>
            <Form.Item
              label="Product Category Description"
              required
              name="productCategoryDescription"
              rules={[
                {
                  required: true,
                  message: "Please enter Product Category Description!",
                },
              ]}
              className={styles.formItem}
            >
              <Input.TextArea
                rows={4}
                placeholder="Enter Product Category Description"
                className={styles.inputTextArea}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.button}
                onClickHandler={() => {}}
              >
                {props.mode === "edit"
                  ? "EDIT PRODUCT CATEGORY"
                  : "CREATE PRODUCT CATEGORY"}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default AdminAddProductCategoryPage;
