import React from "react";
import LoginPage from "../../components/templates/loginPage/LoginPage";
import baseFrontendUrl from "../../constants/baseFrontendUrl";

export const metadata = {
  title: "Sign in | Shri Shyam Surgi Pharma",
  description: `Sign in to Shri Shyam Surgi Pharma - Your one-stop destination for high-quality nutritional food items and 
                surgical products in India. Explore our diverse catalog, shop with confidence, and enjoy fast, secure delivery. 
                Login now to access your account and place your order.`,
  keywords: [
    "Sign in",
    "Shri Shyam Surgi Pharma",
    "nutritional food items",
    "surgical products",
    "pharma wholesale store India",
    "high-quality products",
    "trusted brands",
    "online pharma store",
    "surgical supplies India",
    "nutritional supplements",
    "buy surgical instruments",
    "pharma wholesale supplier",
  ],
  canonicalUrl: "/sign-in",
  openGraph: {
    title: "Sign in | Shri Shyam Surgi Pharma",
    description: `Sign in to Shri Shyam Surgi Pharma - Your one-stop destination for high-quality nutritional food items and 
                  surgical products in India. Explore our diverse catalog, shop with confidence, and enjoy fast, secure delivery. 
                  Login now to access your account and place your order.`,
    url: baseFrontendUrl,
    siteName: "Shri Shyam Surgi Pharma",
    images: [
      {
        url: `${baseFrontendUrl}/og.png`,
        width: 800,
        height: 600,
      },
    ],
    locale: "en_US",
    type: "website",
  },
  twitter: {
    card: "summary_large_image",
    title: "Sign in | Shri Shyam Surgi Pharma",
    description: `Sign in to Shri Shyam Surgi Pharma - Your one-stop destination for high-quality nutritional food items and 
                  surgical products in India. Explore our diverse catalog, shop with confidence, and enjoy fast, secure delivery. 
                  Login now to access your account and place your order.`,
    images: [
      {
        url: `${baseFrontendUrl}/og.png`,
        width: 800,
        height: 600,
      },
    ],
  },
};

const Login = () => {
  return <LoginPage />;
};

export default Login;
