import React, { useState } from "react";
import AdminLoginComponent from "./components/AdminLoginComponent";
import VerificationEmailPage from "./components/CodeVerificationPage";

const AdminLoginPage = () => {
  const [email, setEmail] = useState(null);
  const [registered, setRegistered] = useState(false);
  return registered ? (
    <VerificationEmailPage email={email} />
  ) : (
    <AdminLoginComponent setEmail={setEmail} setRegistered={setRegistered} />
  );
};

export default AdminLoginPage;
