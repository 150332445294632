import React from "react";
import styles from "./verificationEmailPage.module.css";
import { Button, notification } from "antd";
import { useHttpClient } from "hooks/useHttpClient";

const VerificationEmailPage = (props) => {
  const { error, sendRequest } = useHttpClient();

  const onFinish = async () => {
    try {
      const signupResult = await sendRequest(
        "/auth/sign-up",
        "POST",
        JSON.stringify({
          email: props.email,
        })
      );
      if (error !== null) {
        notification.success({
          message: "Success",
          description: signupResult.message,
          placement: "top",
          className: "error-notification",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles.supportLogin}>
      <div className={styles.loginDiv}>
        <div className={styles.imageContainer}>
          <img src="/images/mail.png" alt="mail" />
        </div>
        <h1>Please verify your email</h1>
        <div className={styles.mailContainer}>
          <p>You're almost there! We have sent an email to</p>
          <strong>{props.email}</strong>
        </div>
        <p>
          Just click on the link in that email to complete your signup. If you
          don't see it, you may need to <strong>check your spam</strong> folder.
        </p>
        <p>Still can't find the email? No Problem.</p>
        <Button onClick={onFinish} className={styles.button}>
          Resend Verification Email
        </Button>
      </div>
    </div>
  );
};

export default VerificationEmailPage;
