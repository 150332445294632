import React from "react";
import SignUpPage from "components/templates/signUpPage/SignUpPage";
import baseFrontendUrl from "constants/baseFrontendUrl";

export const metadata = {
  title: "Sign Up | Shri Shyam Surgi Pharma",
  description: `Sign Up for Shri Shyam Surgi Pharma - Your one-stop destination for high-quality nutritional food items and surgical products in India. Create your account, explore our diverse catalog, and shop with confidence. Enjoy fast, secure delivery and stay updated with the latest offers. Join now and start your shopping journey!`,
  keywords: [
    "Sign Up",
    "Shri Shyam Surgi Pharma",
    "nutritional food items",
    "surgical products",
    "pharma wholesale store India",
    "high-quality products",
    "trusted brands",
    "online pharma store",
    "surgical supplies India",
    "nutritional supplements",
    "buy surgical instruments",
    "pharma wholesale supplier",
  ],
  canonicalUrl: "/sign-up",
  openGraph: {
    title: "Sign Up | Shri Shyam Surgi Pharma",
    description: `Sign Up for Shri Shyam Surgi Pharma - Your one-stop destination for high-quality nutritional food items and surgical products in India. Create your account, explore our diverse catalog, and shop with confidence. Enjoy fast, secure delivery and stay updated with the latest offers. Join now and start your shopping journey!`,
    url: baseFrontendUrl,
    siteName: "Shri Shyam Surgi Pharma",
    images: [
      {
        url: `${baseFrontendUrl}/og.png`,
        width: 800,
        height: 600,
      },
    ],
    locale: "en_US",
    type: "website",
  },
  twitter: {
    card: "summary_large_image",
    title: "Sign Up | Shri Shyam Surgi Pharma",
    description: `Sign Up for Shri Shyam Surgi Pharma - Your one-stop destination for high-quality nutritional food items and surgical products in India. Create your account, explore our diverse catalog, and shop with confidence. Enjoy fast, secure delivery and stay updated with the latest offers. Join now and start your shopping journey!`,
    images: [
      {
        url: `${baseFrontendUrl}/og.png`,
        width: 800,
        height: 600,
      },
    ],
  },
};

const SignUp = () => {
  return <SignUpPage />;
};

export default SignUp;
