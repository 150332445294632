import React from "react";
import AdminDashboardPage from "components/templates/adminDashboard/AdminDashboard";
import AdminLayout from "components/layouts/AdminLayout";
import { Helmet } from "react-helmet";

const AdminDashboard = () => {
  return (
    <>
      <Helmet>
        <title>Administrative Panel Dashboard</title>
        <meta
          name="description"
          content="Dashboard for managing administrative tasks."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <AdminLayout>
        <AdminDashboardPage />
      </AdminLayout>
    </>
  );
};

export default AdminDashboard;
