import React from "react";
import AdminAddProductCategoryPage from "components/templates/adminAddProductCategoryPage/AdminAddProductCategoryPage";
import AdminLayout from "components/layouts/AdminLayout";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const AdminEditProductCategory = () => {
  const { productCategoryId } = useParams();
  return (
    <>
      <Helmet>
        <title>Edit Product Category - Admin Panel</title>
        <meta
          name="description"
          content="Edit product category details in the administrative panel."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <AdminLayout>
        <AdminAddProductCategoryPage
          mode="edit"
          productCategoryId={productCategoryId}
        />
      </AdminLayout>
    </>
  );
};

export default AdminEditProductCategory;
